// import type { Plugin } from 'vue'

/**
 *
 * @param component 需要注册的组件
 * @param alias 组件别名
 * @returns any
 */
export const withInstall = <T>(
  component: T,
  alias?: string
) => {
  const comp = component as any
  comp.install = (app: any) => {
    app.component(
      comp.name || comp.displayName,
      component
    )
    if (alias) {
      app.config.globalProperties[alias] =
        component
    }
  }
  return component as T & Plugin
}

/**
 * @param str 需要转下划线的驼峰字符串
 * @returns 字符串下划线
 */
export const humpToUnderline = (
  str: string
): string => {
  return str
    .replace(/([A-Z])/g, '-$1')
    .toLowerCase()
}

/**
 * @param str 需要转驼峰的下划线字符串
 * @returns 字符串驼峰
 */
export const underlineToHump = (
  str: string
): string => {
  if (!str) return ''
  return str.replace(
    /\-(\w)/g,
    (_, letter: string) => {
      return letter.toUpperCase()
    }
  )
}

/**
 * 驼峰转横杠
 */
export const humpToDash = (
  str: string
): string => {
  return str
    .replace(/([A-Z])/g, '-$1')
    .toLowerCase()
}

export const setCssVar = (
  prop: string,
  val: any,
  dom = document.documentElement
) => {
  dom.style.setProperty(prop, val)
}

export const getCssVar = (
  prop: string,
  dom = document.documentElement
) => {
  return getComputedStyle(dom).getPropertyValue(
    prop
  )
}

/**
 * 查找数组对象的某个下标
 * @param {Array} ary 查找的数组
 * @param {Functon} fn 判断的方法
 */
// eslint-disable-next-line
export const findIndex = <T = Recordable>(
  ary: Array<T>,
  fn: Fn
): number => {
  if (ary.findIndex) {
    return ary.findIndex(fn)
  }
  let index = -1
  ary.some(
    (item: T, i: number, ary: Array<T>) => {
      const ret: T = fn(item, i, ary)
      if (ret) {
        index = i
        return ret
      }
    }
  )
  return index
}

export const trim = (str: string) => {
  return str.replace(/(^\s*)|(\s*$)/g, '')
}

/**
 * @param {Date | number | string} time 需要转换的时间
 * @param {String} fmt 需要转换的格式 如 yyyy-MM-dd、yyyy-MM-dd HH:mm:ss
 */
export function formatTime(
  time: Date | number | string,
  fmt: string
) {
  if (!time) return ''
  else {
    const date = new Date(time)
    const o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'H+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds(),
      'q+': Math.floor((date.getMonth() + 3) / 3),
      S: date.getMilliseconds()
    }
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        (date.getFullYear() + '').substr(
          4 - RegExp.$1.length
        )
      )
    }
    for (const k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1
            ? o[k]
            : ('00' + o[k]).substr(
                ('' + o[k]).length
              )
        )
      }
    }
    return fmt
  }
}

/**
 * 生成随机字符串
 */
export function toAnyString() {
  const str: string =
    'xxxxx-xxxxx-4xxxx-yxxxx-xxxxx'.replace(
      /[xy]/g,
      (c: string) => {
        const r: number = (Math.random() * 16) | 0
        const v: number =
          c === 'x' ? r : (r & 0x3) | 0x8
        return v.toString()
      }
    )
  return str
}

/**
 * 首字母大写
 */
export function firstUpperCase(str: string) {
  return str
    .toLowerCase()
    .replace(/( |^)[a-z]/g, (L) =>
      L.toUpperCase()
    )
}

/**
 * 把对象转为formData
 */
export function objToFormData(obj: Recordable) {
  const formData = new FormData()
  Object.keys(obj).forEach((key) => {
    formData.append(key, obj[key])
  })
  return formData
}

// 深拷贝
export function deepClone(target) {
  // 定义一个变量
  let result
  // 如果当前需要深拷贝的是一个对象的话
  if (typeof target === 'object') {
    // 如果是一个数组的话
    if (Array.isArray(target)) {
      result = [] // 将result赋值为一个数组，并且执行遍历
      for (let i in target) {
        // 递归克隆数组中的每一项
        result.push(deepClone(target[i]))
      }
      // 判断如果当前的值是null的话；直接赋值为null
    } else if (target === null) {
      result = null
      // 判断如果当前的值是一个RegExp对象的话，直接赋值
    } else if (target.constructor === RegExp) {
      result = target
    } else {
      // 否则是普通对象，直接for in循环，递归赋值对象的所有值
      result = {}
      for (let i in target) {
        result[i] = deepClone(target[i])
      }
    }
    // 如果不是对象的话，就是基本数据类型，那么直接赋值
  } else {
    result = target
  }
  // 返回最终结果
  return result
}

interface IObject {
  time?: number
  day?: number
}
//获取当前日期 yyyy-mm-dd
export function getNowFormatDate(obj: IObject) {
  let date = new Date()
  let seperator1 = '-'
  if (obj.time) {
    date.setFullYear(
      date.getFullYear() - obj.time
    )
  }
  if (obj.day) {
    date.setDate(date.getDate() - obj.day)
  }
  let year = date.getFullYear()
  let month: Number | String = date.getMonth() + 1
  let strDate: Number | String = date.getDate()
  if (month >= 1 && month <= 9) {
    month = '0' + month
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = '0' + strDate
  }
  let currentdate =
    year +
    seperator1 +
    month +
    seperator1 +
    strDate
  return currentdate
}

export const currentDays = getNowFormatDate({})

export const current18Days = getNowFormatDate({
  time: 18
})

export const current30Days = getNowFormatDate({
  day: 30
})
